<div>
  <header>
    <h1 id="title">NWT WEBCAMS</h1>
  </header>
  <div id="hayriver">
    <a id="river" href="http://207.189.250.166/control/faststream.jpg?stream=full">
      <h2 id="hay">Hay River Webcam (click link)</h2>
    </a>
  </div>
  <table>
    <tr *ngFor="let location of webcams">
      <h2>{{ location.title }}</h2>
      <tbody>
        <td *ngFor="let y of location.cameras">
          <a href="{{ y.thumbnail }}">
            <img src="{{ y.thumbnail }}" width="340" />
          </a>
          <h3>{{ y.name }}</h3>
        </td>
      </tbody>
    </tr>
  </table>
  <footer>
    <div id="footer"><a href="https://visitcanada.live">visitcanada.live</a></div>
    Copyright 2022
  </footer>

</div>